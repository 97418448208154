@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.Landing {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.title {
  width: 40vw;
  min-width: 450px;
  height: 100vh;
  position: absolute;
  border: "0.5px solid rgba(0, 0, 0, 0.15)";
  top: 0;
  border-radius: 8px;
  background-color: white;
}

.image-container {
  position: relative;
  background-image: url("../assets/Landingblock.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  margin: 2vh auto 0 auto;
  width: 80%;
  height: 70vh;
  padding: 40px 40px 40px 40px;
  border-radius: 50px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
}

.bottom-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0; /* Add padding if you want spacing around the image */
}

.bottom-image {
  width: 100%;
  max-width: 1200px; /* Adjust this value to control the image's maximum width */
  height: auto; /* Maintains aspect ratio */
}

.ACmmTitle {
  color: white;
  font-weight: 800;
  font-size: 60px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
}

.secondaryTitle {
  text-align: center;
  color: #f0f0f5;
  font-weight: 400;
  font-size: large;
  margin-bottom: 1rem;
}

.centerButton {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 80px;
  width: 80%;
  margin: 10vh auto 0 auto;
}

.text-content {
  max-width: 50%; /* Ensures text doesn't expand too wide */
}

.section-header {
  font-size: 32px;
  font-weight: bold;
  color: #1a3d5d; /* Darker text color for readability */
  margin-bottom: 15px;
}

.section-header1 {
  font-size: 18px;
  font-weight: bold;
  color: #1a3d5d; /* Darker text color for readability */
  margin-bottom: 15px;
}

.section-subtitle {
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.image-content {
  max-width: 45%; /* Limits image section width */
  text-align: center;
}

.image-content img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow around image */
  margin-bottom: 10px;
}

.image-description {
  font-size: 14px;
  color: #7a7a7a;
}

.image-level img {
  width: 20rem;
  margin-left: 60%;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow around image */
  margin-bottom: 10px;
}
