#table-head-cell {
    font-weight: 700;
    text-transform: uppercase;
}

.css-k1j9js-MuiTableCell-root.data-cell {
    border-bottom: unset;
}

.css-16tv36q-MuiTableCell-root.data-cell {
    border-bottom: unset;
}