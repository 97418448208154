.alert-text {
  font-size: 1.1rem;
  padding-block: 0.5rem;
}

.alert-button-text {
  font-family: Roboto;
  font-size: 1rem;
  padding-block: 0.5rem;
}

.icon {
  margin: 0.5rem;
  margin-top: 0.7rem;
}

.dialog-text {
  font-family: Roboto;
  font-size: 1rem;
}
